import React, { useEffect } from "react"

const Form = ({ url, transparency }) => {
  const callTypeForm = () => {
    var js,
      q,
      d = document,
      gi = d.getElementById,
      ce = d.createElement,
      gt = d.getElementsByTagName,
      id = "typef_orm",
      b = "https://embed.typeform.com/"
    if (!gi.call(d, id)) {
      js = ce.call(d, "script")
      js.id = id
      js.src = b + "embed.js"
      q = gt.call(d, "script")[0]
      q.parentNode.insertBefore(js, q)
    }
  }

  useEffect(() => {
    callTypeForm()
  })

  return (
    <div
      className="typeform-widget"
      data-url={url}
      data-transparency={transparency}
      data-hide-headers="true"
      data-hide-footer="true"
      style={{ width: `100%`, height: `500px` }}
    ></div>
  )
}

export default Form
