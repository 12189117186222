import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Layout from "../components/layout"
import HeroCustom from "../components/HeroCustom"
import Image from "../components/Image"
import SEO from "../components/seo"
import "../styles/styles.scss"

import absSvg from "../images/abs.svg"
import weightWomanSvg from "../images/weight-woman.svg"
import coinPng from "../images/coin.png"

const SimpleCard = ({ iconSrc, alt, title, text }) => {
  return (
    <div className="card is-shadowless is-third is-flex-grow-1">
      <div className="card-image px-5 pt-5 is-flex is-justify-content-center">
        <figure className="image">
          <img
            className="m-0 svg-primary"
            src={iconSrc}
            alt={alt}
            style={{ maxWidth: `80px` }}
          />
        </figure>
      </div>
      <div className="card-content has-text-centered">
        <div className="title is-size-4 has-text-weight-light has-text-black">
          {title}
        </div>
        <div className="content is-size-6">{text}</div>
      </div>
    </div>
  )
}

const ValueProposition = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="section pt-6">
      <div className="container">
        <div className="columns">
          <div className="column is-one-third">
            <Image data={data} className="border-radius-sm has-boxshadow" />
          </div>
          <div className="column ml-6 is-flex is-flex-direction-column is-justify-content-center">
            <h2 className="title is-size-2 has-text-weight-normal">
              Le Netflix du fitness à domicile.
            </h2>
            <h3 className="subtitle is-size-3 has-text-weight-light mt-5">
              Meoh-live est une nouvelle startup de fitness numérique conçu par
              les coachs pour apporter le plaisir du sport directement dans
              votre salon.
            </h3>
          </div>
        </div>
        <div className="is-flex is-justify-content-space-between is-flex-wrap-wrap pt-6">
          <SimpleCard
            iconSrc={absSvg}
            alt="icon abs"
            title="Choisissez votre emploi du temps"
            text="Un planning créé par vous en fonction de vos préférences."
          />
          <SimpleCard
            iconSrc={weightWomanSvg}
            alt="icon strong"
            title="Trouvez le coach parfait pour vous"
            text="Avec notre algorithme de recommandation, découvrez l'instructeur qui vous correspond."
          />
          <SimpleCard
            iconSrc={coinPng}
            alt="icon coin"
            title="Méthode de paiement équitable"
            text="Payez seulement ce que ce que vous consommez (fini l’abonnement annuel à la salle de sport qui n’est pas rentabilisé)."
          />
        </div>
        <div className="is-flex is-justify-content-center my-6">
          <Link to="#form">
            <div className="button is-primary">m'inscrire maintenant</div>
          </Link>
        </div>
      </div>
    </section>
  )
}

const Footer = () => {
  return (
    <div className="section has-background-black">
      <div className="container is-flex is-justify-content-center">
        <a
          href="https://pretotype.fr"
          className="has-text-primary"
          target="_blank"
          rel="noreferrer"
        >
          pretotype.fr
        </a>
      </div>
    </div>
  )
}

const IndexPage = () => (
  <Layout>
    <SEO />
    <HeroCustom />
    <ValueProposition />
    <Footer />
  </Layout>
)

export default IndexPage
